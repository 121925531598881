import React from "react"
import PropTypes from "prop-types"
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Get_quotation from './Quotation'
import axios from 'axios';

class Backendcallapi extends React.Component {
  static quotation = (event) => {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      let headersOptions =  {
                            headers: {
                              Accept: 'application/json',
                              'Content-Type': 'application/json',
                              'X-CSRF-Token': csrf
                            }
                          }
      let bodyParam = { user: { user_id:event.user_id,system: event.system,type: event.type} }
      return axios.post('/quotation_mail.json',bodyParam,headersOptions)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      })
  }

  static watts = (url) =>{
    return axios.get(url,{headers: { 'Content-Type': 'application/json' }})
    .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
    })
  }

  static add_visitor = (csrf,headersOptions,bodyParam,currentObj) =>{
    axios.post('/create_visitor.json', bodyParam, headersOptions)
    .then((response) => {
      currentObj.props.closeModal();
      if(currentObj.props.type.includes('manload') || currentObj.props.type.includes('find_on')){
        currentObj.props.manualLoad(response)
      }else{
        Get_quotation(currentObj.props.total,response.data.user.id,currentObj.props.type)
      }
    })
    .catch((error) => {
      currentObj.setState({errors: error.response.data.errors})
    })
  }
}
export default Backendcallapi
