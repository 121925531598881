import React from "react"
import PropTypes from "prop-types"
import Mail from './Backendcallapi'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
const handle_request = (data) => {
 if (data.error)
    toast.error(data.error, {autoClose:3000})
  else
    toast.success(data.message, {autoClose:3000})
}

const Get_quotation = async(total,user,type) =>{
  let args = {user_id:user,system: total,type: type}
  let response = await Mail.quotation(args)
  handle_request(response)
}
export default Get_quotation;
